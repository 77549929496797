import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutherobottom from "../images/about-page-hero-bottom-img.png"
// import member from "../images/team-member/team-1.png"
import ctaarrow from "../images/team-member/right-arrow.svg"
import work from "../images/why-us-icons/wnp-icon-1.svg"
import time from "../images/why-us-icons/wnp-icon-2.svg"
import experience from "../images/why-us-icons/wnp-icon-3.svg"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="About Us - National Partners"
        description="National Partners was founded by a team of highly qualified and experienced finance executives who are dedicated to providing the highest standards in the premium finance industry."
      />
      <div className="page-headline">
        <div className="hero-headline about-page">
          <div className="container">
            <div className="section-heading text-center">
              <div className="hero-body">
                <h1 className="hero-page-title">About Us</h1>
                <h2 className="hero-subtitle text-white">
                  National Partners was founded by a team of highly qualified
                  and experienced finance executives who are dedicated to
                  providing the highest standards in the premium finance
                  industry.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img
          src={aboutherobottom}
          className="hero-bottom-img img-fluid w-100"
        />
      </div>
      <div className="page-content pb-280">
        <section className="about-us bg-white-blue pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-heading text-center mb-5">
                  <h6 className="section-subtitle text-gold text-uppercase">
                    OUR STORY
                  </h6>
                  <hr className="section-hr" />
                  <h2 className="section-title">
                    We Make Premium Finance Easy
                  </h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-10 mx-auto text-center">
                <h4 className="f-400">
                  Our goal is to make certain that the process of financing
                  business insurance is simple and efficient. We don’t believe
                  in unnecessary bureaucracy or extra complications. We want to
                  get you the best  terms possible for your business insurance
                  policies.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="our-team">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-heading text-center mb-5">
                  <h6 className="section-subtitle text-gold text-uppercase">
                    OUR TEAM
                  </h6>
                  <hr className="section-hr" />
                  <h2 className="section-title">A Team Dedicated to You</h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-10 mx-auto text-center mb-5">
                <h4 className="f-400">
                  National Partners was founded by a team of highly qualified
                  and experienced finance executives who are dedicated to
                  providing the highest standards in the premium finance
                  industry.
                </h4>
              </div>
              <div className="col-12">
                <h3 className="team-rank text-left">Rank / Department</h3>
              </div>
              {data?.allSanityPerson?.edges?.length
                ? data.allSanityPerson.edges.map(({ node }, idx) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={idx}>
                      <div className="team-member h-100">
                        <img
                          src={node?.image?.asset?.fluid?.srcWebp}
                          className="team-member-img img-fluid w-100"
                        />
                        <div className="team-member-info">
                          <h3 className="team-member-name">{node.name}</h3>
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="team-member-ranked">
                              {node.position}
                            </h4>
                            <Link
                              className="team-info-modal"
                              to={`/${node?.slug.current}`}
                            >
                              <img
                                src={ctaarrow}
                                className="team-member-cta img-fluid"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </section>
        <section className="why-us pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5">
                <div className="section-heading text-center mb-5">
                  <h6 className="section-subtitle text-gold text-uppercase">
                    WHY US
                  </h6>
                  <hr className="section-hr" />
                  <h2 className="section-title">Why National Partners?</h2>
                </div>
              </div>
              <div className="col-lg-3 ml-lg-auto px-lg-4 col-md-4 text-center">
                <img src={work} className="img-fluid" alt="Work" />
                <h5 className="f-400">
                  We work with clients of all sizes and in all industries.
                </h5>
              </div>
              <div className="col-lg-3 px-lg-4 col-md-4 text-center">
                <img src={time} className="img-fluid" alt="Time" />
                <h5 className="f-400">
                  There are fewer delays or frustrations.
                </h5>
              </div>
              <div className="col-lg-3 mr-lg-auto px-lg-4 col-md-4 text-center">
                <img src={experience} className="img-fluid" alt="Experience" />
                <h5 className="f-400">
                  We have experience in the industry and understand what you
                  need.
                </h5>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    allSanityPerson {
      edges {
        node {
          id
          position
          name
          slug {
            current
          }
          image {
            asset {
              fluid {
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
